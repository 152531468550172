import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { IoAddOutline } from 'react-icons/io5';
import { useHotkeys } from 'react-hotkeys-hook';
import {
    MEETING_TOP_EDGE,
    SUBPROJECT_ID_KEY,
    SUBPROJECT_MEETING_EDGE,
    store,
    PROJECT_ID_KEY,
    useGraph,
    MEETING_ATTENDEE_EDGE,
    CREATE_DASHBOARD_TOP_MODAL,
    GROUPED_TOPS_KEY,
    TOP_ATTACHMENT_EDGE,
} from '../../common/hooks.config.new';
import { useModal, useModalState } from '../../hooks/useModal';
import { Modal } from '../Common/Modal';
import { Toast } from '../Elements/Toast';
import { ListRow } from '../Common/ListRow';
import { ProtocolTop } from '../Elements/ProtocolScreen/ProtocolTop';
import { useNodeFromVar } from '../../hooks/useNode';
import { createDummyGraph, createOfflinePush } from '../../common/got-adjunct';
import { useToast } from '../../hooks/useToast';
import { useUserEmail } from '../../hooks/useUserEmail';
import { useContact } from '../../hooks/useContact';
import { getTopInfo } from '../../common/topinfo.util';
import { TopButtonRow } from '../Elements/TopButtonRow';
import { newId, newIsoDate, newMsDate } from '../../common/util';
import { SaveButton } from '../Elements/SaveButton';
import { refreshAttachments } from '../../hooks/useAttachments';
import { AttachmentsModal, MODAL_ATTACHMENTS_DASHBOARD_TOP } from './AttachmentsModal';
import { getDummyMeetingId } from '../../hooks/useCreateHistory';

const DEFAULT_BID = 'Dashboard Item';

export const CreateDashboardTopModal = ({ stack: parentStack }) => {
    const [isOpen] = useModalState('createDashboardTop');
    const stack = R.append(CREATE_DASHBOARD_TOP_MODAL, parentStack);
    const { clear } = useGraph(...stack);
    return (
        <div className="modal-container-compensation">
            <Modal
                name="createDashboardTop"
                style={{
                    height: '90vh',
                    top: '5vh',
                    width: '90vw',
                    left: '5vw',
                }}
                close={clear}
                topbar={<IoAddOutline size={24} color="var(--corporate-color-7)" />}
            >
                {isOpen ? <CreateDashboardTopModalContent stack={stack} /> : null}
            </Modal>
        </div>
    );
};

const selectTopTypes = topMap => {
    if (!topMap) return [];

    const topBags = R.values(topMap);
    return topBags.map(topBag => topBag.node.type);
};
const useTopTypes = (stack, topIds) => {
    const { useView } = useGraph(...stack);

    const view = {};
    const subView = { include: { node: true } };
    for (let i = 0; i < topIds.length; i += 1) {
        if (topIds[i]) {
            view[topIds[i]] = subView;
        }
    }

    return useView(view, selectTopTypes);
};

const CreateDashboardTopModalContent = ({ stack }) => {
    const [topInfos, setTopInfos] = useState([]);
    const [topToCreate] = useModalState('createDashboardTop');

    const { update, node } = useGraph(...stack);

    const { node: project } = useNodeFromVar(stack, PROJECT_ID_KEY);
    const { node: subproject } = useNodeFromVar(stack, SUBPROJECT_ID_KEY);

    const dummyMeeting = {
        id: getDummyMeetingId(subproject.id),
        isDummy: true,
        title: 'DUMMY',
        closed: true,
        date: newIsoDate(),
    };

    const user = useUserEmail();
    const { contact } = useContact(stack, project.id, user);

    const author = {
        node: contact.node,
        nodeId: contact.nodeId,
        metadata: { presence: 'M', order: newMsDate() },
    };

    const createTop = templateTop => {
        const subprojectId = store.getVar(...stack)(SUBPROJECT_ID_KEY);
        const { add, inheritRights, assoc, getGraph } = createDummyGraph();

        add(SUBPROJECT_MEETING_EDGE)(subprojectId)(dummyMeeting);
        inheritRights(dummyMeeting.id)(subprojectId);
        assoc(MEETING_ATTENDEE_EDGE)(dummyMeeting.id)(contact.node, {
            presence: 'M',
            order: newMsDate(),
        });

        const newTop = {
            id: newId(),
            createdDate: newIsoDate(),
            businessId: DEFAULT_BID,
            dashboard: true,
            type: templateTop?.type || '',
            status: templateTop?.status || '',
            category: templateTop?.category || '',
        };
        add(MEETING_TOP_EDGE)(dummyMeeting.id)(newTop, { order: newMsDate() });
        inheritRights(newTop.id)(subprojectId);

        store.mergeGraph(getGraph(), CREATE_DASHBOARD_TOP_MODAL);

        // update topinfo
        const topInfo = getTopInfo({
            project,
            subproject,
            meeting: dummyMeeting,
            attendees: [author],
            top: newTop,
            protocolId: 'DB',
        });
        setTopInfos(tops => [...tops, topInfo]);
    };

    useEffect(() => {
        createTop(topToCreate);
    }, []);

    useHotkeys('shift+enter', () => createTop(), { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] });

    const setTopBusinessID = (topInfo, index, indexStr) => {
        const top = node(topInfo.top.id);
        if (!top?.type) return;

        const pbid = project.businessId || project.title;
        const spbid = subproject.businessId || subproject.title;
        const id = R.slice(9, 13, top.id);
        const topType = top.type || 'missingType';
        const topBusinessId = `${pbid}-${spbid}-Dashboard-${id}-${topType}-${indexStr}`;

        if (topInfo.businessId === topBusinessId) return;

        const newTopInfo = {
            ...topInfo,
            businessId: topBusinessId,
            top: { ...topInfo.top, businessId: topBusinessId },
        };
        setTopInfos(R.assoc(index, newTopInfo));
        update(R.assoc('businessId', topBusinessId, top));
    };

    const topIds = topInfos.map(topInfo => topInfo.top.id);
    const topTypes = useTopTypes(stack, topIds);
    useEffect(() => {
        const { tops: groupedProjectTops } = store.getVar(...stack)(GROUPED_TOPS_KEY);
        const noOfTops = R.length(R.values(groupedProjectTops));

        let indexStrCounter = 0;
        for (let i = 0; i < topInfos.length; i += 1) {
            const topInfo = topInfos[i];
            if (topInfo.businessId !== DEFAULT_BID && groupedProjectTops[topInfo.businessId]) {
                continue;
            }

            const indexStr = (noOfTops + indexStrCounter).toString().padStart(3, '0');
            setTopBusinessID(topInfo, i, indexStr);
            indexStrCounter += 1;
        }
    }, [topTypes]);

    const setToast = useToast('dashboard');
    const toastConfig = {
        textOnStart: 'Saving data...',
        textOnSuccess: 'Successfully saved.',
        textOnError: 'You are offline. Please sync when back online.',
    };
    const push = createOfflinePush(stack, setToast, toastConfig);

    const save = async () => {
        const { uploads } = await push();
        uploads.subscribe({
            complete: () => {
                topInfos.map(topInfo =>
                    refreshAttachments(stack, topInfo.top.id, TOP_ATTACHMENT_EDGE, true),
                );
            },
        });
        uploads.start();
    };

    const openAddAttachmentsModal = useModal(MODAL_ATTACHMENTS_DASHBOARD_TOP);
    const [insertionIndex, setInsertionIndex] = useState();

    return (
        <>
            <div className="columns">
                <div>
                    <div className="modal-headline">
                        <div>Create item</div>
                    </div>
                    {topInfos.map((topInfo, index) => (
                        <ListRow
                            key={topInfo.top.id}
                            style={{
                                marginLeft: '1rem',
                                marginRight: '1rem',
                                boxShadow:
                                    insertionIndex === index
                                        ? '0px 12px 24px rgba(0, 0, 0, 0.25'
                                        : '0 5px 8px -2px var(--shadow-color-normal)',
                                transform: insertionIndex === index && 'scale(1.02)',
                                transition: '0.3s ease',
                            }}
                        >
                            <div
                                className="top-search-row"
                                onFocus={() => setInsertionIndex(index)}
                                onBlur={() => setInsertionIndex()}
                            >
                                <TopButtonRow
                                    top={topInfo}
                                    stack={stack}
                                    onAttachmentClick={() =>
                                        openAddAttachmentsModal({
                                            fromId: topInfo.top.id,
                                            edgeTypes: TOP_ATTACHMENT_EDGE,
                                            inheritFromId: subproject.id,
                                            shouldPush: false,
                                        })
                                    }
                                    noEdit
                                />
                                <ProtocolTop
                                    stack={stack}
                                    topId={topInfo.top.id}
                                    inheritFromId={subproject.id}
                                    editable
                                    noButton
                                    noId
                                    allowDrag={topInfo.top.type}
                                />
                            </div>
                        </ListRow>
                    ))}
                    <div className="protocol-notice" style={{ margin: 0, marginTop: 24 }}>
                        Press Shift + Enter to add multiple dashboard items
                    </div>
                </div>
            </div>
            <SaveButton save={save} />
            <Toast name="createDashboardTop" />
            <AttachmentsModal stack={stack} name={MODAL_ATTACHMENTS_DASHBOARD_TOP} />
        </>
    );
};

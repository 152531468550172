import * as R from 'ramda';
import { businessNodeString } from './util';

export const getTopInfo = ({ project, subproject, meeting, attendees, top, responsibles }) => {
    const validType = R.compose(R.complement(Number), R.prop('type'))(top);
    if (validType && top && top.id) {
        const businessId = R.prop('businessId', top);
        const topInfo = {
            businessId,
            meetingDate: R.propOr('', 'date', meeting),
            createdDate: R.propOr('', 'createdDate', top),
            modifiedDate: R.propOr('', 'modifiedDate', top),
            editedDate:
                R.propOr(false, 'modifiedDate', top) ||
                R.propOr(false, 'date', meeting) ||
                R.propOr(false, 'createdDate', top),
            topType: R.prop('type', top),
            subproject: businessNodeString(subproject),
            subprojectId: R.prop('id', subproject),
            meeting: businessNodeString(meeting),
            meetingType: R.prop('type', meeting),
            meetingId: R.prop('id', meeting),
            protocolId: top.dashboard
                ? 'DB'
                : R.join('-', [
                      R.propOr('', 'businessId', project),
                      R.propOr('', 'businessId', subproject),
                      R.propOr('', 'businessId', meeting),
                  ]),
            responsible: R.compose(R.propOr(undefined, 'node'), R.head, R.values)(responsibles),
            author: R.compose(
                R.propOr(undefined, 'node'),
                R.find(R.pathEq(['metadata', 'presence'], 'M')),
                R.values,
            )(attendees),
            top,
        };

        return topInfo;
    }
    return null;
};
